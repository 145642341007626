import { __decorate } from "tslib";
import { Component, Vue, Ref, Prop, Emit, Watch, } from 'vue-property-decorator';
import { UserPageType, NodeType, Strategy, } from '../../../typings/organization/org-manage';
import { OrgManageService } from '@/sevices';
import fileDownlow from '@/common/data-download';
import axios from 'axios';
import { StaffSelector, CTable } from '@cloudpivot-hermes/common-components';
import * as Utils from '@/common/utils';
import Perm from '@/router/permission';
const CancelToken = axios.CancelToken;
let cancel = null;
let UserTable = class UserTable extends Vue {
    constructor() {
        super(...arguments);
        this.isShowImport = false;
        this.isShowAddUser = false;
        this.isShowExportUserLoading = false;
        this.isHasTableList = false;
        this.updateDeptLoading = false;
        this.copyUserLoading = false;
        this.exportUserProgress = 0;
        this.searchParams = {};
        this.keyword = '';
        this.deptDetail = this.generateDeptDetail();
        this.selectionRow = [];
        this.NodeType = NodeType;
        this.Strategy = Strategy;
        this.uploadApi = OrgManageService.uploadUser;
        this.tempApi = OrgManageService.downloadUserTemplate;
        this.uploadParams = {};
        this.toggleDept = false;
        this.toggleDeptLoading = true;
        /**
         * table列定义
         */
        this.tableColumns = [
            {
                width: 60,
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                fixed: 'left',
                slots: { title: 'indexTitle', name: '序号' },
                scopedSlots: { customRender: 'index' },
            },
            {
                width: 180,
                dataIndex: 'name',
                key: 'name',
                ellipsis: true,
                slots: { title: 'nameTitle', name: '姓名' },
                scopedSlots: { customRender: 'name' },
            },
            {
                width: 150,
                dataIndex: 'position',
                key: 'position',
                ellipsis: true,
                slots: { title: 'positionTitle', name: '职位' },
            },
            {
                width: 240,
                dataIndex: 'tags',
                key: 'tags',
                slots: { title: 'tagsTitle', name: '标签' },
                scopedSlots: { customRender: 'tags' },
            },
            {
                width: 160,
                dataIndex: 'account',
                key: 'account',
                ellipsis: true,
                slots: { title: 'accountTitle', name: '账号' },
            },
            {
                width: 140,
                dataIndex: 'mobile',
                key: 'mobile',
                ellipsis: true,
                slots: { title: 'mobileTitle', name: '手机号' },
                scopedSlots: { customRender: 'mobile' },
            },
            {
                dataIndex: 'mainDepartment',
                key: 'mainDepartment',
                ellipsis: true,
                slots: { title: 'mainDepartmentTitle', name: '所属主部门' },
                scopedSlots: { customRender: 'mainDepartment' },
            },
            {
                width: 140,
                dataIndex: 'action',
                key: 'action',
                fixed: 'right',
                align: 'center',
                slots: { title: 'actionTitle', name: '操作' },
                scopedSlots: { customRender: 'action' },
            },
        ];
    }
    get enableCheckbox() {
        // 只有自维护才可以操作用户
        return this.selectedDeptDetail.relatedSyncType === 'PUSH' && this.isHasTableList;
    }
    get isShowBatchBtn() {
        return !!this.selectionRow.length;
    }
    get excludeSelfDept() {
        return [this.deptDetail?.id];
    }
    get addUserDeptDetail() {
        return {
            id: this.selectedDeptDetail.id,
            name: this.selectedDeptDetail.name,
            orgId: this.selectedDeptDetail.orgId,
            organizationName: this.selectedDeptDetail.organizationName,
            namePath: this.selectedDeptDetail.namePath,
        };
    }
    get hasUserTablePerm() {
        return Perm.hasCodePerm(this.$PermCode.organization.orgUserManage);
    }
    onKeywordChange() {
        this.searchParams.name = this.searchParams.name?.replace(/^\s*|\s*$/g, '');
    }
    async onSelectedDeptDetailChange() {
        if (!this.selectedDeptDetail.id)
            return;
        this.toggleDept = true;
        this.toggleDeptLoading = true;
        await this.getDeptDetail();
        this.toggleDeptLoading = false;
        this.searchParams = {};
        await this.setDynamicTableHeight();
        this.toggleDept = false;
    }
    /**
     * 切换到人员详情页
     */
    showUserDetail(userDetail) {
        return {
            userDetail: userDetail,
            showUserPageType: UserPageType.UserDetail,
        };
    }
    /**
     * 表格数据发生变化时会触发
     */
    changeTable() {
        if (this.ctable) {
            this.isHasTableList = !!this.ctable.tableData.length;
            this.selectionRow = this.ctable.getSelectionRow();
        }
    }
    /**
     * 显示添加人员弹窗
     */
    addUser() {
        this.isShowAddUser = true;
    }
    /**
     * 导入人员弹窗
     */
    importUser() {
        this.uploadParams = {
            orgConfigId: this.deptDetail.orgId,
        };
        this.isShowImport = true;
    }
    /**
     * 批量调整人员部门
     */
    batchUpdateDept() {
        const userList = this.selectionRow;
        if (!userList.length) {
            this.$message.warn('请先勾选需要调整部门的人员');
            return;
        }
        this.updateDeptStaffSelector.showModal();
    }
    /**
     * 批量调整人员部门
     */
    updateDept(depts) {
        if (!depts.length) {
            this.$message.warning('请选择部门');
            return;
        }
        const userList = this.selectionRow;
        const userIds = userList.map(item => item.id) || [];
        this.updateDeptLoading = true;
        const params = { userIds: userIds, targetDepartmentId: depts[0]?.id, sourceDepartmentId: this.deptDetail.id };
        OrgManageService.batchUpdateDept(params).then((res) => {
            if (res.success) {
                // 更新部门
                return this.getDeptDetail();
            }
        }).then((deptDetail) => {
            if (deptDetail.id) {
                // 更新用户列表
                return this.ctable.getTableList();
            }
        }).then((success) => {
            if (success) {
                this.updateDeptStaffSelector.closeModal();
            }
        }).finally(() => {
            this.updateDeptLoading = false;
        });
    }
    /**
     * 从其它部门复制用户
     */
    copyUserFromDept() {
        this.copyUserStaffSelector.showModal();
    }
    /**
     * 从其它部门复制用户
     */
    copyUser(users) {
        if (!users.length) {
            this.$message.warning('请选择用户');
            return;
        }
        this.copyUserLoading = true;
        const userIds = users.map(item => item.id) || [];
        const params = { userIds: userIds, departmentId: this.deptDetail.id };
        OrgManageService.copyUserFromDept(params).then((res) => {
            if (res.success) {
                // 更新部门
                return this.getDeptDetail();
            }
        }).then((deptDetail) => {
            if (deptDetail.id) {
                // 更新用户列表
                return this.ctable.getTableList();
            }
        }).then((success) => {
            if (success) {
                this.copyUserStaffSelector.closeModal();
            }
        }).finally(() => {
            this.copyUserLoading = false;
        });
    }
    /**
     * 删除人员
     */
    delUser(item) {
        this.$confirm({
            title: '确定删除该人员吗？',
            content: '删除后，当前部门该人员信息将会被清除。',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    OrgManageService.deleteUser({
                        id: item.id,
                        deptId: this.deptDetail.id,
                    }).then((res) => {
                        if (res.success) {
                            this.$message.success('删除成功');
                            this.ctable.getTableList();
                            resolve(null);
                        }
                    }).catch((e) => {
                        reject(e);
                    });
                });
            },
        });
    }
    /**
     * 批量删除人员
     */
    batchDelUser() {
        const userList = this.selectionRow;
        if (!userList.length) {
            this.$message.warn('请先勾选需要删除的人员');
            return;
        }
        const departmentId = this.deptDetail.id;
        const userIds = userList.map(item => item.id) || [];
        this.$confirm({
            title: '确定删除选中人员吗？',
            content: '删除后，当前部门选中的人员信息将会被清除。',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    OrgManageService.batchDeleteUser({
                        userIds: userIds.join(','),
                        departmentId: departmentId,
                    }).then((res) => {
                        if (res.success) {
                            this.$message.success('删除成功');
                            this.ctable.getTableList();
                            resolve(null);
                        }
                    }).catch((e) => {
                        reject(e);
                    });
                });
            },
        });
    }
    /**
     * 导出人员
     */
    exportUser() {
        const userList = this.selectionRow;
        if (!userList.length) {
            this.$message.warn('请先勾选需要导出的人员');
            return;
        }
        const departmentId = this.deptDetail.id;
        const userIds = userList.map(item => item.id) || [];
        this.exportUserProgress = 1;
        this.isShowExportUserLoading = true;
        OrgManageService.exportUser({
            departmentId: departmentId,
            userIds: userIds,
        }, (e) => {
            if (e.lengthComputable) {
                const progress = Math.floor((e.loaded / e.total) * 100);
                this.exportUserProgress = progress;
            }
        }).then(res => {
            // 获取后端返回的文件名
            const headers = res.orgResponse.headers || {};
            const disposition = headers['content-disposition'] || '';
            const unDecodefileName = disposition.split('=')[1] || '';
            const fileStream = res.orgResponse.data;
            const fileType = 'application/octet-stream';
            const fileName = decodeURIComponent(unDecodefileName);
            fileDownlow.downloadByPost(fileStream, fileType, fileName);
            this.exportUserProgress = 100;
        }).finally(() => {
            setTimeout(() => {
                this.isShowExportUserLoading = false;
                this.exportUserProgress = 0;
            }, 500);
        });
    }
    /**
     * 用户置顶
     */
    userToTop(item) {
        item.loading = true;
        OrgManageService.userToTop({
            userId: item.id,
            departmentId: this.deptDetail.id,
        }).then((res) => {
            if (res.success) {
                this.$message.success('置顶成功');
                this.ctable.getTableList();
            }
        }).finally(() => {
            item.loading = false;
        });
    }
    /**
     * 切换到编辑人员页面
     */
    editUser(userDetail) {
        return {
            userDetail: userDetail,
            showUserPageType: UserPageType.UserEdit,
        };
    }
    /**
     * 动态设置表格高度
     */
    async setDynamicTableHeight() {
        await this.setTableHeight();
        await this.$nextTick();
        const ctable = await Utils.getComponentByRefs(this.$refs, 'ctable');
        if (ctable) {
            ctable.setScrollHeight();
            await ctable.getTableList();
        }
    }
    /**
     * 设置表格的高度
     */
    async setTableHeight() {
        await this.$nextTick();
        const deptDetailHeight = this.deptDetailDom?.$el?.offsetHeight;
        const controlWrapperHeight = this.controlWrapperDom?.offsetHeight;
        const parentEle = this.$parent.$el;
        const orgRight = parentEle.querySelector('.js-org-right');
        if (!this.orgTable)
            return;
        this.orgTable.style.height = orgRight.offsetHeight - (deptDetailHeight + controlWrapperHeight) + 'px';
    }
    /**
     * 搜索
     */
    async search(keyword) {
        this.searchParams = {
            name: keyword,
        };
        this.keyword = keyword;
        this.ctable.pagination.current = 1;
        this.ctable.pagination.pageSize = 20;
        this.ctable.getTableList();
    }
    /**
     * 获取列表数据接口
     */
    getList(params, options) {
        const p = {
            ...params,
            ...this.searchParams,
            id: this.selectedDeptDetail.id,
        };
        return new Promise((resolve) => {
            OrgManageService.getUserList(p, options).then((res) => {
                const data = res.data || [];
                const result = [];
                data.forEach((item) => {
                    const resultItem = {
                        id: item.id,
                        baseUserId: item.baseUserId,
                        name: item.name,
                        account: item.username,
                        imgUrl: item.imgUrl,
                        mobile: item.mobile,
                        loading: false,
                        deptId: item.mainDepartmentId,
                        position: item.position,
                        tags: item.labels?.map(tag => tag.name) || [],
                        mainDepartmentPath: item.mainDepartmentFullNamePath,
                        mainDepartment: item.mainDepartmentName,
                    };
                    result.push(resultItem);
                });
                res.data = result;
                resolve(res);
            });
        });
    }
    /**
     * 通过接口获取部门详情
     */
    getDeptDetail() {
        return new Promise((resolve) => {
            if (typeof cancel === 'function') {
                cancel();
            }
            OrgManageService.getDeptDetail({ id: this.selectedDeptDetail.id }, {
                cancelToken: new CancelToken((c) => {
                    cancel = c;
                }),
            }).then((res) => {
                if (res.success) {
                    this.deptDetail = this.generateDeptDetail(res.data);
                    resolve(this.deptDetail);
                }
            }).finally(() => {
                cancel = null;
            });
        });
    }
    /**
     * 生成部门详情数据
     */
    generateDeptDetail(data = {}) {
        const selectedDeptDetail = this.selectedDeptDetail;
        const deptDetail = {
            id: data.id,
            name: data.name,
            nodeType: data.isRoot ? NodeType.Org : NodeType.Dept,
            sourceType: selectedDeptDetail.sourceType,
            relatedSyncType: selectedDeptDetail.relatedSyncType,
            orgId: data.orgId,
            organizationName: data.organizationName,
            curUserNum: data.employees,
            orgUserNum: data.orgAllEmployees,
            supervisorName: data.managerName,
            supervisorId: data.managerId,
            parentId: data.parentId,
            level: data.level,
            namePath: data.path,
            tagsList: Array.isArray(data.labels) && data.labels.map(item => item.name),
        };
        const result = {};
        for (const key in deptDetail) {
            result[key] = key.includes('id') || key.includes('Id') || key.includes('ID')
                ? deptDetail[key] : this.getValue(deptDetail[key]);
        }
        return result;
    }
    getValue(detail) {
        let result = detail;
        if (typeof detail === 'string' || typeof detail === 'undefined' || detail === null)
            result = detail || '--';
        return result;
    }
    ;
    reload() {
        this.getDeptDetail().then(() => {
            this.search();
        });
    }
};
__decorate([
    Ref()
], UserTable.prototype, "updateDeptStaffSelector", void 0);
__decorate([
    Ref()
], UserTable.prototype, "copyUserStaffSelector", void 0);
__decorate([
    Ref()
], UserTable.prototype, "deptDetailDom", void 0);
__decorate([
    Ref()
], UserTable.prototype, "controlWrapperDom", void 0);
__decorate([
    Ref()
], UserTable.prototype, "orgTable", void 0);
__decorate([
    Ref()
], UserTable.prototype, "ctable", void 0);
__decorate([
    Prop({ default: () => { } })
], UserTable.prototype, "selectedDeptDetail", void 0);
__decorate([
    Watch('searchParams.name')
], UserTable.prototype, "onKeywordChange", null);
__decorate([
    Watch('selectedDeptDetail', { immediate: true, deep: true })
], UserTable.prototype, "onSelectedDeptDetailChange", null);
__decorate([
    Emit('toggleComponentEvent')
], UserTable.prototype, "showUserDetail", null);
__decorate([
    Emit('toggleComponentEvent')
], UserTable.prototype, "editUser", null);
UserTable = __decorate([
    Component({
        name: 'UserTable',
        components: {
            CTable: CTable,
            AddUser: () => import('./add-user.vue'),
            DeptDetailComp: () => import('./dept-detail.vue'),
            TableDropDownTag: () => import('./table-dropdown-tag.vue'),
            TableDropDownPath: () => import('./table-dropdown-path.vue'),
            ImportComp: () => import('./import-comp.vue'),
            DownLoadProgress: () => import('./downLoadProgress.vue'),
            Avatar: () => import('./avatar.vue'),
            ResetPassword: () => import('./reset-password.vue'),
            NoPermission: () => import('@/components/global/no-permission.vue'),
            StaffSelector,
        },
    })
], UserTable);
export default UserTable;
